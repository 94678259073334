import React from "react";
import { Input } from "@fluentui/react-components";

const Subject = ({ subject, handleSubjectChangeValue }) => {
  return (
    <div>
      <span
        style={{
          border: "1px solid black",
          width: "64px",
          display: "inline-block",
          height: "22px",
          borderRadius: "5px",
          paddingLeft: "9px",
          marginTop: "7px",
        }}
      >
        Subject
      </span>
      <Input
        size="small"
        value={subject}
        onChange={(e) => handleSubjectChangeValue(e.target.value)}
        style={{ width: "200px", marginLeft: "10px" }}
      />
    </div>
  );
};

export default Subject;
