const client_id = "753688f4-6cc9-4568-87fd-28a309a9dc62";
const client_secret = "KuZ8Q~f1OorVgYP7FFN5OrvJG8nLK~~SnN3KOcz1";
const redirectUrl = "https://www.logimail.site/commands.html";
//const redirectUrl = "https://localhost:3000/commands.html";
const baseUrl = "https://www.api.logimail.site/";
//const baseUrl = "https://localhost:7145/";

//Get access token
async function getAccessToken() {
  return new Promise(function (resolve, reject) {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject("Error obtaining access token");
      }
    });
  });
}

export async function SignIn() {
  var url =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" +
    client_id +
    "&response_type=code&redirect_uri=" +
    redirectUrl +
    "&response_mode=query&scope=Mail.Send offline_access&state=12345";

  let loginDialog;
  function processMessage(arg) {
    loginDialog.close();

    let code = arg.message;

    //Getting access and refresh toekn
    getToken(code);

    return;
  }

  return new Promise((resolve, reject) => {
    Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (result) {
      if (result.error) {
        reject(result.error);
      } else {
        loginDialog = result.value;
        loginDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, function (arg) {
          try {
            const data1 = processMessage(arg);
            resolve(data1);
          } catch (error) {
            reject(error);
          }
        });
      }
    });
  });
}
async function getToken(code) {
  return new Promise((resolve, reject) => {
    let tokenDetails = {
      ClientId: "753688f4-6cc9-4568-87fd-28a309a9dc62",
      ClientSecret: "KuZ8Q~f1OorVgYP7FFN5OrvJG8nLK~~SnN3KOcz1",
      RedirectUrl: "https://www.logimail.site/commands.html",
      Code: code,
      RefreshToken: "",
    };

    fetch(baseUrl + "api/Auth/ObtainToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tokenDetails),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseToken) => {
        console.log("Access token response:", responseToken);
        // Storing token for future use
        window.localStorage.setItem("accessToken", responseToken.access_token);
        window.localStorage.setItem("refreshToken", responseToken.refresh_token);
        resolve(responseToken);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
}
async function getNewToken() {
  return new Promise((resolve, reject) => {
    let client_id = "753688f4-6cc9-4568-87fd-28a309a9dc62";
    let client_secret = "KuZ8Q~f1OorVgYP7FFN5OrvJG8nLK~~SnN3KOcz1";
    let redirectUrl = "https://www.logimail.site/commands.html";
    let tokenDetails = {
      ClientId: client_id,
      ClientSecret: client_secret,
      RedirectUrl: redirectUrl,
      Code: "",
      RefreshToken: window.localStorage.getItem("refreshToken"),
    };

    fetch(baseUrl + "api/Auth/RefreshToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tokenDetails),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseToken) => {
        console.log("Refresh token response:", responseToken);
        window.localStorage.setItem("accessToken", responseToken.access_token);
        window.localStorage.setItem("refreshToken", responseToken.refresh_token);
        resolve(responseToken);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
}

// Helper function to check email count and update if necessary
function checkEmailCount() {
  let emailCountData = JSON.parse(window.localStorage.getItem("emailCount"));
  let today = new Date().toDateString();

  if (!emailCountData) {
    // Initialize email count if not found
    window.localStorage.setItem("emailCount", JSON.stringify({ date: today, emailCount: 300 }));
    return { emailCount: 300 };
  }

  // If the stored date is not today, reset the count

  // Check if the count is under 300
  if (emailCountData.emailCount > 0) {
    return { emailCount: emailCountData.emailCount };
  }
}

// Helper function to update the email count in localStorage
function updateEmailCount(newCount) {
  let currentDate = new Date().toDateString();
  window.localStorage.setItem("emailCount", JSON.stringify({ date: currentDate, emailCount: newCount }));
}

export async function sendEmail(mailGroups, message, subject, applySignature, signature, ccList) {
  const sendPromises = [];
  let token = await getAccessToken();
  const regex = /<p>([^<]+)<\/p>/g;

  // Check email count before sending
  let { emailCount } = checkEmailCount();

  if (emailCount == 0) {
    console.log("Email limit for today exceeded.");
    return false; // Exit early if the limit is reached
  }

  let isFirstEmail = true; // Track whether it's the first email in the first group

  mailGroups.forEach((group) => {
    let match;
    let emailIndex = 1; // Initialize email index for each group
    while ((match = regex.exec(group.emails)) !== null) {
      const email = match[1].trim();

      // Only proceed if the email count is under 300
      if (emailCount > 0) {
        const delay = 1000 * emailIndex;

        // Only apply ccList to the first email of the first group
        let currentCcList = isFirstEmail ? ccList : [];

        // Push the email sending task to promises with the calculated delay
        sendPromises.push(
          sendEmailWithDelay(email, subject, message, delay, token, applySignature, signature, currentCcList)
        );

        // Set isFirstEmail to false after the first email is sent
        isFirstEmail = false;

        emailIndex++;

        // Update the email count after adding an email
        emailCount--;
        updateEmailCount(emailCount);
      } else {
        console.log("Reached the email sending limit for today.");
        break; // Stop adding emails if the limit is reached
      }
    }
  });

  return Promise.all(sendPromises);
}

async function sendEmailWithDelay(userEmail, subject, message, delay, token, applySignature, signature, ccList) {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        await sendEmailInternal(userEmail, subject, message, token, applySignature, signature, ccList);
        resolve();
      } catch (error) {
        reject(error);
      }
    }, delay);
  });
}

async function sendEmailInternal(toRecipient, subject, message, token, applySignature, signature, ccList) {
  let CCrecipients = [];
  if (ccList.length > 0) {
    CCrecipients = ccList.map((email) => {
      if (email != "") {
        return {
          EmailAddress: {
            Address: email,
          },
        };
      }
    });
  } else {
    CCrecipients = [];
  }
  let content = "";
  let PostBody;
  if (applySignature) {
    content = `
    ${message}
    ${signature.text}
    <br/>
    <img src="${signature.base64}}" />
  `;
  } else {
    content = `${message}`;
  }
  if (CCrecipients.length > 0) {
    PostBody = {
      Message: {
        Subject: subject,
        Body: {
          ContentType: "HTML",
          Content: content,
        },
        ToRecipients: [
          {
            EmailAddress: {
              Address: toRecipient,
            },
          },
        ],
        CcRecipients: CCrecipients,
      },
    };
  } else {
    PostBody = {
      Message: {
        Subject: subject,
        Body: {
          ContentType: "HTML",
          Content: content,
        },
        ToRecipients: [
          {
            EmailAddress: {
              Address: toRecipient,
            },
          },
        ],
      },
    };
  }

  return fetch("https://outlook.office.com/api/v2.0/me/sendmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(PostBody),
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response;
    })
    .catch((error) => {
      console.error(error);
      if (error.status == 401 || error.status == 403) {
        // Attempt to refresh token
        return getNewToken().then(() => sendEmailInternal(toRecipient, message));
      } else {
        throw error;
      }
    });
}
async function readFileAsBase64(selectedFiles) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const base64String = e.target.result;
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(selectedFiles);
  });
}
