import React from "react";

const From = () => {
  return (
    <div>
      <span
        style={{
          border: "1px solid black",
          width: "64px",
          display: "inline-block",
          height: "22px",
          borderRadius: "5px",
          paddingLeft: "17px",
          marginTop: "7px",
        }}
      >
        From
      </span>
      <span>&nbsp;&nbsp;&nbsp;{window.localStorage.getItem("fromEmail")}</span>
    </div>
  );
};

export default From;
