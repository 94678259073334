import React from "react";
import { MessageBar, MessageBarBody } from "@fluentui/react-components";

const NotificationBar = ({ message, intent }) => {
  return (
    <div>
      <div
        className="d-flex "
        style={{
          position: "fixed",
          zIndex: 23,
          top: "50%",
          left: "20px",
        }}
      >
        <MessageBar intent={intent} style={{ width: "280px" }}>
          <MessageBarBody>{message}</MessageBarBody>
        </MessageBar>
      </div>
    </div>
  );
};

export default NotificationBar;
