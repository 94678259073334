import React, { useState } from "react";
import { Button, Input } from "@fluentui/react-components";
const SaveTemplates = ({ handleSaveTemplate, templateTitle, setTemplateTitle }) => {

  const handleTemplateTitle = (e) => {
    setTemplateTitle(e.target.value);
  };

  const handleSaveTemplateTitle = () => {
    handleSaveTemplate();
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Input size="small" value={templateTitle} onChange={handleTemplateTitle} placeholder="Enter template name" />
          <div>
            <Button
              size="small"
              className="float-end mt-1"
              appearance="primary"
              disabled={!templateTitle}
              onClick={handleSaveTemplateTitle}
            >
              Save Template
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTemplates;
