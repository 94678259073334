import React from "react";

const LoginButton = ({ onLogin }) => {
  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: "15px 10px 10px 20px",
          }}
        >
          In order to send email with Mailer,please Authenticate first.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="row">
            <div>
              <a
                style={{
                  display: "flex",
                  height: "44px",
                }}
                href="#"
                className="btn btn-primary form-control-sm w-100"
                id="signinMicrosoft_button"
                onClick={onLogin}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  style={{ float: "left" }}
                >
                  <title>MS-SymbolLockup</title>
                  <rect x="1" y="1" width="9" height="9" fill="#f25022"></rect>
                  <rect x="1" y="11" width="9" height="9" fill="#00a4ef"></rect>
                  <rect x="11" y="1" width="9" height="9" fill="#7fba00"></rect>
                  <rect x="11" y="11" width="9" height="9" fill="#ffb900"></rect>
                </svg>
                Sign In with Microsoft
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginButton;
