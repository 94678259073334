import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Tooltip } from "@fluentui/react-components";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Input,
  Label,
  makeStyles,
} from "@fluentui/react-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NotificationBar from "../../Notification/NotificationBar";
import { Spinner } from "@fluentui/react-components";
import CloseIcon from "@mui/icons-material/Close";
import { createTemplate } from "../../Services/TemplatesApi";
import { getAllTemplates } from "../../Services/TemplatesApi";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    height: "55vh",
  },
});

const CreateTemplate = ({ setTemplates }) => {
  const styles = useStyles();
  const [loader, setLoader] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationIntent, setNotificationIntent] = useState("");
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContentChange = (html) => {
    setFormData((prevData) => ({
      ...prevData,
      content: html,
    }));
  };

  const handleSaveTemplate = (e) => {
    e.preventDefault();
    setOpen(false);
    createTemplate(formData.title, formData.content)
      .then((res) => {
        getAllTemplates().then((res) => {
          setTemplates(res); // Update the templates state
        });
        setLoader(false);
        setNotify(true);
        setNotificationMessage("Template Created Successfully");
        setNotificationIntent("success");
        setTimeout(() => {
          setNotify(false);
        }, 2000);
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setNotify(true);
        setNotificationIntent("error");
        setNotificationMessage(error.message);
        setTimeout(() => {
          setNotify(false);
        }, 2000);
      });
  };

  return (
    <>
      {notify && <NotificationBar message={notificationMessage} intent={notificationIntent} />}
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p style={{ fontWeight: "bold", color: "black" }}>Loading...</p>
        </div>
      )}
      <div className="flex items-center justify-end mb-2 mr-5">
        <Dialog
          style={{ margin: "10px", height: "80vh" }}
          open={open}
          onOpenChange={(event, data) => setOpen(data.open)}
        >
          <DialogTrigger disableButtonEnhancement>
            <Tooltip content="Add Template" relationship="label">
              <AddCircleOutlineIcon color="success" sx={{ fontSize: 17, cursor: "pointer" }} />
            </Tooltip>
          </DialogTrigger>
          <DialogSurface>
            <form onSubmit={handleSaveTemplate}>
              <DialogBody>
                <div className="flex justify-between w-[220%]">
                  <DialogTitle>Create Template</DialogTitle>
                  <DialogTrigger disableButtonEnhancement>
                    <CloseIcon sx={{ cursor: "pointer" }} />
                  </DialogTrigger>
                </div>
                <DialogContent className={styles.content}>
                  <Label>Template Title</Label>
                  <Input
                    required
                    size="small"
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                  <Label className="mt-3">Template Content</Label>
                  <ReactQuill
                    theme="snow"
                    name="content"
                    value={formData.content}
                    onChange={handleContentChange}
                    style={{ height: "130px", marginTop: "5px" }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ color: [] }],
                        ["link", "image"],
                        ["clean"],
                      ],
                      imageDrop: true,
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "color",
                      "background",
                      "link",
                      "image",
                    ]}
                  />
                </DialogContent>
                <DialogActions className="flex-row justify-end">
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Cancel</Button>
                  </DialogTrigger>
                  <Button type="submit" appearance="primary" disabled={!formData.content || !formData.title}>
                    Submit
                  </Button>
                </DialogActions>
              </DialogBody>
            </form>
          </DialogSurface>
        </Dialog>
        <p onClick={() => setOpen(true)} className="m-0 p-0 ms-1 cursor-pointer">
          Create Template
        </p>
      </div>
    </>
  );
};

export default CreateTemplate;
