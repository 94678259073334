import React from "react";
import {
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  makeStyles,
  Button,
} from "@fluentui/react-components";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  span: {
    border: "1px solid black",
    width: "59px",
    display: "inline-block",
    height: "29px",
    padding: "3px",
    paddingLeft: "17px",
  },
  message: {
    color: "blue",
    fontWeight: "500",
    fontSize: "17px",
    marginBottom: "0px",
    marginTop: "10px",
  },
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});

const DeleteGroup = ({ onClose, onDelete }) => {
  const styles = useStyles();

  return (
    <div>
      <DialogSurface>
        <form>
          <DialogBody>
            <Row
              style={{
                width: "300px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col xs={6}>
                <DialogTitle>Delete Group</DialogTitle>
              </Col>
              <Col xs={4}></Col>
              <Col xs={2}>
                <CloseIcon onClick={onClose} />
              </Col>
            </Row>

            <DialogContent className={styles.content}>
              <p>Are you sure to delete this group?</p>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                alignItems: "end",
                marginRight: "10px",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Button appearance="primary" className="w-25" onClick={onDelete}>
                Yes
              </Button>
              <Button className="w-25" onClick={onClose}>
                No
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </div>
  );
};

export default DeleteGroup;
