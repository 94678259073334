export async function handleApiUsage() {
  const url = "https://www.api.logimail.site/api/User/ApiUsage";
  const data = {
    userName: window.localStorage.getItem("displayName"),
    userEmail: window.localStorage.getItem("fromEmail"),
    api: "email send",
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}
