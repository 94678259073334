import React from "react";
import { useState } from "react";
import { Select, makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});
const UseTemplate = ({ templates, setContent }) => {
  const styles = useStyles();

  const handleTemplateChange = (event) => {
    if (event.target.value !== "0") {
      setContent(event.target.value);
    }
  };

  return (
    <div>
      {templates.length > 0 ? (
        <Select onChange={handleTemplateChange} size="small" className={styles.truncatedText}>
          <option value="0">Select Template</option>
          {templates.map((item) => (
            <option value={item.templateContent} key={item.templateTitle}>
              {item.templateTitle}
            </option>
          ))}
        </Select>
      ) : (
        <p>No saved templates available. Please create one to use.</p>
      )}
    </div>
  );
};

export default UseTemplate;
