const baseUrl = "https://www.api.logimail.site/";

//const baseUrl = "https://localhost:7145/";

export async function getAllTemplates() {
  try {
    const response = await fetch(baseUrl + "api/User/UserTemplates?email=" + window.localStorage.getItem("fromEmail"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 204) {
      // If the response is 204 (No Content), return null
      return null;
    }

    if (!response.ok) {
      throw new Error("Failed to fetch user templates");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching user templates:", error);
    throw error;
  }
}

export async function createTemplate(title, content) {
  return new Promise(function (resolve, reject) {
    const data = {
      userEmail: window.localStorage.getItem("fromEmail"),
      templateTitle: title,
      templateContent: content,
    };

    fetch(baseUrl + "api/User/UserTemplates", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const responseData = response; // Parse response as JSON

        if (!response.ok) {
          // Return the error response with status code and message
          reject({
            status: response.status,
            statusText: response.statusText,
            message: responseData.message || "An error occurred", // Use server message if available
          });
        }

        // Success response
        resolve(responseData);
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject({
          status: 500,
          message: "An unexpected error occurred",
          error: error.message, // Catch network or other unexpected errors
        });
      });
  });
}

export async function updateTemplate(template) {
  return new Promise(function (resolve, reject) {
    fetch(baseUrl + "api/User/UserTemplates", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(template),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}

export async function deleteTemplate(id) {
  return new Promise(function (resolve, reject) {
    fetch(baseUrl + "api/User/UserTemplates?templateId=" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update database");
        }
        return response;
      })
      .then((response) => {
        console.log(response);
        resolve();
      })
      .catch((error) => {
        console.error("Error updating database:", error);
        reject(error);
      });
  });
}
