export async function loadingPlace(place) {
  let loadingDialog;

  let url = "https://www.logimail.site/LoadingPlace.html?address=" + encodeURIComponent(place);

  function loadingProcessMessage(arg) {
    loadingDialog.close();
  }

  Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (result) {
    if (result.error) {
      console.log(result.error);
    } else {
      loadingDialog = result.value;
      loadingDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogEventReceived, loadingProcessMessage);
    }
  });
}
export async function showRoute(loading, unLoading) {
  let routeDialog;

  let url =
    "https://localhost:3000/Route.html?loading=" +
    encodeURIComponent(loading) +
    "&unLoading=" +
    encodeURIComponent(unLoading);

  function routeProcessMessage(arg) {
    routeDialog.close();
  }

  Office.context.ui.displayDialogAsync(url, { height: 60, width: 50 }, function (result) {
    if (result.error) {
      console.log(result.error);
    } else {
      routeDialog = result.value;
      routeDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogEventReceived, routeProcessMessage);
    }
  });
}
