import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardPreview } from "@fluentui/react-components";
import { makeStyles, Button, Caption1, Text, tokens, Subtitle1 } from "@fluentui/react-components";
const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },

  card: {
    width: "270px",
    maxWidth: "100%",
    height: "fit-content",
    marginBottom: "15px",
  },

  section: {
    width: "fit-content",
    fontSize: "10px",
  },
  header: {
    fontSize: "12px",
    width: "104px",
    textAlign: "center",
  },
  title: { margin: "0 0 12px" },

  horizontalCardImage: {
    width: "64px",
    height: "64px",
  },

  headerImage: {
    borderRadius: "4px",
    maxWidth: "44px",
    maxHeight: "44px",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: { margin: "0" },
});
export default function PaymentPlans() {
  const styles = useStyles();
  const Pricing = [
    {
      Price: "Limited time offer - 3 euros per week",
      URL: "https://buy.stripe.com/test_dR628u4eW7z05Pi5km",
    },
    {
      Price: "Standard plan - 9 euros per 30 days",
      URL: "https://buy.stripe.com/test_cN2eVgeTA5qS3Ha8wz",
    },
    {
      Price: "Comfort plan - 19 euros per 90 days",
      URL: "https://buy.stripe.com/test_5kA7sO9zg3iK5Pi6os",
    },
  ];
  const navigate = useNavigate();
  const gotoPaymentsScreen = (url) => {
    //gotoPayment("https://buy.stripe.com/dR69E4bM70qZ1uU28d", 0);
    navigate("/makepayment", { state: { url: url } });
  };

  const goToHome = () => {
    navigate("/Home");
  };

  return (
    <div>
      <div className="h-screen flex justify-center">
        <div>
          <div style={{ height: "60px" }} className="ml-6">
            <h2 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "15px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={goToHome}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                  />
                </svg>
              </span>
              <span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "5px" }}>
                Subscription Plans
              </span>
            </h2>
          </div>
          <div className={styles.main}>
            <section className={styles.section}>
              <section className={styles.section}>
                <section className={styles.section}>
                  {Pricing.map((plan, index) => (
                    <Card key={index} className={styles.card} orientation="horizontal">
                      <CardHeader
                        header={
                          <Text weight="semibold" className={styles.header}>
                            {plan.Price}
                          </Text>
                        }
                        action={
                          <Button appearance="primary" onClick={() => gotoPaymentsScreen(plan.URL)}>
                            Buy
                          </Button>
                        }
                      />
                    </Card>
                  ))}
                </section>
              </section>
            </section>
          </div>

          <p
            className="m-4"
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            If you have any questions, or want to discuss a custom plan - email us at
            <b> logimail@balber.lt</b>
          </p>

          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "12px",
            }}
            className="m-4"
          >
            Free Trial : {window.localStorage.getItem("daysLeft")} days left
          </p>

          <div className={styles.main}>
            <Card className={styles.card} orientation="horizontal">
              <CardHeader
                header={
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                    weight="semibold"
                  >
                    Extend free access by <br /> referring a friend
                  </Text>
                }
                action={
                  <Button
                    style={{
                      width: "100px",
                    }}
                    appearance="primary"
                  >
                    Learn More
                  </Button>
                }
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
