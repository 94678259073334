import React from "react";
import { Tooltip } from "@fluentui/react-components";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

const InsertTemplate = () => {
  const handleInsertTemplate = () => {
    console.log("Template inserted");
  };
  return (
    <div>
      <Tooltip content="Insert" relationship="label">
        <NoteAddIcon onClick={handleInsertTemplate} sx={{ fontSize: 17, cursor: "pointer" }} />
      </Tooltip>
    </div>
  );
};

export default InsertTemplate;
