import React from "react";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
const GetStart = () => {
  const navigate = useNavigate();
  const handleStart = () => {
    navigate("/Home");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          paddingTop: "130px",
        }}
      >
        <img src="assets/icon-80.png" alt="" width={100} />
        <h5
          style={{
            textAlign: "center",
            padding: "7px",
          }}
        >
          Logimail AI is a tool that helps you handle transportation requests faster.
        </h5>
        <Button appearance="primary" onClick={handleStart}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default GetStart;
