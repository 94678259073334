import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useNavigate } from "react-router-dom";
import { Card, CardPreview } from "@fluentui/react-components";
import { makeStyles, tokens } from "@fluentui/react-components";
import EditTemplate from "../EditTemplate/EditTemplate";
import DeleteTemplate from "../DeleteTemplate/DeleteTemplate";
import InsertTemplate from "../InsertTemplate/InsertTemplate";
import CreateTemplate from "../CreateTemplate/CreateTemplate";
import { getAllTemplates } from "../../Services/TemplatesApi";
import { Spinner } from "@fluentui/react-components";
import { updateTemplate, deleteTemplate } from "../../Services/TemplatesApi";
import NotificationBar from "../../Notification/NotificationBar";
const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  card: {
    width: "300px",
    maxWidth: "100%",
    height: "fit-content",
    marginBottom: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#caf0f8",
    },
  },
  section: {
    width: "fit-content",
    fontSize: "10px",
  },
  header: {
    fontSize: "12px",
    width: "200px",
    textAlign: "center",
  },
  title: { margin: "0 0 12px" },
  horizontalCardImage: {
    width: "64px",
    height: "64px",
  },
  headerImage: {
    borderRadius: "4px",
    maxWidth: "44px",
    maxHeight: "44px",
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  text: { margin: "0" },
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});

export default function AllTemplates() {
  const styles = useStyles();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [loader, setLoader] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationIntent, setNotificationIntent] = useState("");
  useEffect(() => {
    setLoader(true);
    getAllTemplates().then((res) => {
      console.log(res);
      setTemplates(res);
      setLoader(false);
    });
  }, []);

  // Function to handle editing a template
  const handleEditTemplate = (template, setOpen) => {
    // Handle update logic here, using formData
    console.log("Updated template data:", template);
    setOpen(false);
    setLoader(true);
    updateTemplate(template)
      .then((res) => {
        console.log(res);
        getAllTemplates().then((res) => {
          setTemplates(res); // Update the templates state
        });
        setLoader(false);
        setNotify(true);
        setNotificationIntent("success");
        setNotificationMessage("Template Updated Successfully");
        setTimeout(() => {
          setNotify(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        setNotificationIntent("error");
        setNotificationMessage("Something went wronge");
      });
  };
  const handleDeleteTemplate = (templateId, setOpen) => {
    setOpen(false);
    setLoader(true);
    // Handle update logic here, using formData
    console.log("Deleted template Id:", templateId);
    deleteTemplate(templateId)
      .then((res) => {
        console.log(res);
        getAllTemplates().then((res) => {
          setTemplates(res); // Update the templates state
        });
        setLoader(false);
        setNotify(true);
        setNotificationIntent("success");
        setNotificationMessage("Template Deleted Successfully");
        setTimeout(() => {
          setNotify(false);
        }, 2000);
      })

      .catch((error) => {
        console.log(error);
        setLoader(false);
        setNotificationIntent("error");
        setNotificationMessage("Something went wronge");
      });
  };
  const goToHome = () => {
    navigate("/Home");
  };

  return (
    <div>
      {notify && <NotificationBar message={notificationMessage} intent={notificationIntent} />}
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p
            style={{
              fontWeight: "bold",
              color: "black",
            }}
          >
            Loading...
          </p>
        </div>
      )}
      <div className="h-screen flex justify-center">
        <div>
          <div style={{ height: "60px" }} className="ml-6">
            <h2 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <span
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "15px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
                onClick={goToHome}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left-circle"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                  />
                </svg>
              </span>
              <span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "5px" }}>Templates</span>
            </h2>
          </div>
          <div className="text-end">
            <CreateTemplate setTemplates={setTemplates} />
          </div>
          <div className={styles.main}>
            {templates.map((template) => (
              <Card key={template.id} className={styles.card} orientation="horizontal">
                <CardPreview className="flex m-1">
                  <div>
                    <p className="overflow-x-auto whitespace-nowrap font-semibold w-52 p-0 m-0">
                      {template.templateTitle}
                    </p>
                    <p className="overflow-y-auto  h-10 w-52 p-0 m-0">
                      <p dangerouslySetInnerHTML={{ __html: template.templateContent }} />
                    </p>
                  </div>
                  <div
                    className="space-x-2 mt-[18px] ml-[3px]"
                    style={{
                      display: "flex",
                    }}
                  >
                    <EditTemplate template={template} onEdit={handleEditTemplate} />
                    <DeleteTemplate templateId={template.id} onDelete={handleDeleteTemplate} />
                    <InsertTemplate />
                  </div>
                </CardPreview>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
