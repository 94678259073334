import * as React from "react";
import PropTypes from "prop-types";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import Home from "./Home";
import Logimail from "./LogimalAI/Logimail";
import Profile from "./Profile";
import PaymentPlans from "./PaymentPlans/PaymentPlans";
import Makepayment from "./MakePayment/Makepayment";
import ExpireSubscription from "./ExpireSubscription";
import OpenAI from "./OpenAI/OpenAI";
import GetStart from "./GetStart";
import AllTemplates from "./Templates/AllTemplates/AllTemplates";
import "./index.css";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Router>
        <Routes>
          <Route path="/" element={<GetStart />}></Route>
          <Route path="/Home" element={<Home />}></Route>
          <Route path="/Logimail" element={<Logimail />}></Route>
          <Route path="/OpenAI" element={<OpenAI />}></Route>
          <Route path="/ExpireSubscription" element={<ExpireSubscription />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/subscriptions" element={<PaymentPlans />}></Route>
          <Route path="/makepayment" element={<Makepayment />}></Route>
          <Route path="/AllTemplates" element={<AllTemplates />}></Route>
        </Routes>
      </Router>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
