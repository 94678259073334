import React from "react";
import { Tooltip } from "@fluentui/react-components";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  DialogSurface,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  makeStyles,
  Button,
} from "@fluentui/react-components";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  span: {
    border: "1px solid black",
    width: "59px",
    display: "inline-block",
    height: "29px",
    padding: "3px",
    paddingLeft: "17px",
  },
  message: {
    color: "blue",
    fontWeight: "500",
    fontSize: "17px",
    marginBottom: "0px",
    marginTop: "10px",
  },
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});

const DeleteTemplate = ({ templateId, onDelete }) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);

  const onConfirmDelete = () => {
    onDelete(templateId, setOpen);
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
        <Tooltip content="Delete" relationship="label">
          <DeleteOutlineIcon sx={{ fontSize: 17, cursor: "pointer", color: "red" }} onClick={() => setOpen(true)} />
        </Tooltip>
        <DialogSurface>
          <form>
            <DialogBody>
              <Row
                style={{
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Col xs={8}>
                  <DialogTitle>Delete Template</DialogTitle>
                </Col>
                <Col xs={2}></Col>
                <Col xs={2}>
                  <CloseIcon className="cursor-pointer" onClick={() => setOpen(false)} />
                </Col>
              </Row>

              <DialogContent className={styles.content}>
                <p>Are you sure to delete this Template?</p>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  alignItems: "end",
                  marginRight: "10px",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button appearance="primary" className="w-25" onClick={onConfirmDelete}>
                  Yes
                </Button>
                <Button className="w-25" onClick={() => setOpen(false)}>
                  No
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default DeleteTemplate;
