import React from "react";
import AddIcon from "@mui/icons-material/Add";

import { useRestoreFocusTarget, Button } from "@fluentui/react-components";
const AddSignature = ({ setOpenFileDialog, openMessageBox }) => {
  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  return (
    <div>
      <Button
        appearance="transparent"
        size="small"
        onClick={() => {
          // it is the user responsibility to open the dialog
          setOpenFileDialog(true);
        }}
      >
        <AddIcon /> Add Signature
      </Button>

      <a {...restoreFocusTargetAttribute} onClick={openMessageBox}>
        <img
          src="assets/textareaPopUp.jpg"
          alt=""
          width={17}
          style={{
            float: "right",
            cursor: "pointer",
          }}
        />
      </a>
    </div>
  );
};

export default AddSignature;
