import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "@fluentui/react-components";
import { useLocation } from "react-router-dom";
import NotificationBar from "../Notification/NotificationBar";
const OpenAI = () => {
  const location = useLocation();
  const { message } = location.state || {};
  const navigate = useNavigate();
  const [aiKey, setApiKey] = useState("");
  const handleInputChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleClick = () => {
    if (aiKey) {
      window.localStorage.setItem("openai_key", aiKey);
      navigate("/Logimail");
      console.log("API key saved successfully!");
    } else {
      console.log("Please enter a valid API key.");
    }
  };

  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <img
          src="assets/icon-128.png"
          alt=""
          crossOrigin="anonymous"
          width={100}
          height={100}
          style={{
            paddingBottom: "30px",
          }}
        />
        <p
          style={{
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {message || "Please Enter your OpenAI key"}
        </p>
        <Input
          type="text"
          value={aiKey}
          onChange={handleInputChange}
          style={{
            width: "90%",
          }}
        />
        <Button
          appearance="primary"
          style={{
            marginTop: "30px",
          }}
          onClick={handleClick}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default OpenAI;
