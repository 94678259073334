import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ExpireSubscription = () => {
  const navigate = useNavigate();
  const gotoPayments = () => {
    navigate("/subscriptions");
  };
  return (
    <div>
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-10 col-md-6">
            Your subscription is expird. Please purchase a plan to continue
            <button className="btn btn-primary mt-3 w-100" onClick={gotoPayments}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpireSubscription;
