import React from "react";
import Chip from "@mui/material/Chip";

const GroupTo = ({ selectedGroups, setSelectedGroups }) => {
  return (
    <div>
      <div className="d-flex">
        <span
          style={{
            border: "1px solid black",
            width: "65px",
            display: "inline-block",
            height: "22px",
            borderRadius: "5px",
            paddingLeft: "25px",
            marginRight: "8px",
            paddingRight: "30px",
          }}
        >
          To
        </span>
        <div>
          {selectedGroups.map((selectedGroup, index) => (
            <Chip
              key={index}
              label={selectedGroup.groupName}
              onDelete={() => {
                setSelectedGroups((prevSelectedGroups) =>
                  prevSelectedGroups.filter((group) => group.groupName !== selectedGroup.groupName)
                );
              }}
              style={{ display: "inline-flex", height: "24px", marginRight: "5px" }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupTo;
