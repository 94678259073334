import React from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { red } from "@mui/material/colors";
import { EditRegular, DeleteRegular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
const SelectGroup = ({
  searchQuery,
  groups,
  setSelectedGroups,
  handleFavoriteChange,
  handleEdit,
  setOpenTable,
  handleDelete,
  selectedGroups,
}) => {
  return (
    <div>
      {(searchQuery === "" || searchQuery === null) && (
        <Table
          striped
          bordered
          hover
          className="h-25"
          style={{
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Groups</th>
              <th>Emails</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups
              .filter((group) => group.isFavorite) // Filter to get favorite groups
              .map((group, index) => (
                <tr key={index}>
                  <td>
                    <div className="mb-3">
                      <Form.Check
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedGroups((prevSelectedGroups) => [...prevSelectedGroups, group]);
                          } else {
                            setSelectedGroups((prevSelectedGroups) =>
                              prevSelectedGroups.filter((selectedGroup) => selectedGroup.id !== group.id)
                            );
                          }
                        }}
                        checked={selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)}
                      />
                    </div>
                  </td>
                  <td>{group.groupName}</td>
                  <td>{group.mailsCount}</td>
                  <td className="d-flex">
                    <Checkbox
                      icon={<Favorite sx={{ color: red[800] }} />}
                      checkedIcon={<Favorite sx={{ color: red[800] }} />}
                      style={{
                        padding: "0px",
                        opacity: "0.7",
                      }}
                      checked={group.isFavorite}
                      onChange={(event) => handleFavoriteChange(event.target.checked, group.id)}
                    />

                    <Button
                      onClick={() => {
                        handleEdit(group);
                        setOpenTable(false);
                      }}
                      icon={<EditRegular />}
                      appearance="transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <Button
                      onClick={() => handleDelete(group)}
                      icon={<DeleteRegular />}
                      aria-label="Delete"
                      appearance="transparent"
                    />
                  </td>
                </tr>
              ))}

            {groups
              .filter((group) => !group.isFavorite) // Filter to get non-favorite groups
              .sort((a, b) => a.groupName.localeCompare(b.groupName)) // Sort non-favorite groups alphabetically
              .map((group, index) => (
                <tr key={index}>
                  <td>
                    <div className="mb-3">
                      <Form.Check
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedGroups((prevSelectedGroups) => [...prevSelectedGroups, group]);
                          } else {
                            setSelectedGroups((prevSelectedGroups) =>
                              prevSelectedGroups.filter((selectedGroup) => selectedGroup.id !== group.id)
                            );
                          }
                        }}
                        checked={selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)}
                      />
                    </div>
                  </td>
                  <td>{group.groupName}</td>
                  <td>{group.mailsCount}</td>
                  <td className="d-flex">
                    <Checkbox
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite sx={{ color: red[800] }} />}
                      style={{
                        padding: "0px",
                        opacity: "0.7",
                      }}
                      checked={group.isFavorite}
                      onChange={(event) => handleFavoriteChange(event.target.checked, group.id)}
                    />

                    <Button
                      onClick={() => {
                        handleEdit(group);
                        setOpenTable(false);
                      }}
                      icon={<EditRegular />}
                      appearance="transparent"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <Button
                      onClick={() => handleDelete(group)}
                      icon={<DeleteRegular />}
                      aria-label="Delete"
                      appearance="transparent"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      {searchQuery !== "" && searchQuery !== null && (
        <Table
          striped
          bordered
          hover
          className="h-25"
          style={{
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Groups</th>
              <th>Emails</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups
              .filter((group) => group.groupName.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((group, index) => (
                <tr key={index}>
                  <td>
                    <div className="mb-3">
                      <Form.Check
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedGroups((prevSelectedGroups) => [...prevSelectedGroups, group]);
                          } else {
                            setSelectedGroups((prevSelectedGroups) =>
                              prevSelectedGroups.filter((selectedGroup) => selectedGroup.id !== group.id)
                            );
                          }
                        }}
                        checked={selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)}
                      />
                    </div>
                  </td>
                  <td>{group.groupName}</td>
                  <td>{group.mailsCount}</td>

                  <td className="d-flex">
                    <Checkbox
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite sx={{ color: red[800] }} />}
                      style={{
                        padding: "0px",
                        opacity: "0.7",
                      }}
                      checked={group.isFavorite}
                      onChange={(event) => handleFavoriteChange(event.target.checked, group.id)}
                    />

                    <Button
                      onClick={() => {
                        handleEdit(group);
                      }}
                      icon={<EditRegular />}
                      appearance="transparent"
                    />
                    <Button
                      onClick={() => handleDelete(group)}
                      icon={<DeleteRegular />}
                      aria-label="Delete"
                      appearance="transparent"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default SelectGroup;
