import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner, makeStyles } from "@fluentui/react-components";
import { makePayment } from "../Services/HandlePayment";

const useStyles = makeStyles({
  loaderoverlay: {
    position: "fixed",
    top: "50%",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: ".5",
    zIndex: "10099",
  },
});

const MakePayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const styles = useStyles();
  const [loader, setLoader] = useState(false);

  const proceedPayment = () => {
    setLoader(true);
    makePayment(location.state.url)
      .then(() => {
        setLoader(false);
        navigate("/Home");
      })
      .catch((error) => {
        console.log("Error:", error);
        setLoader(false);
      });
  };
  const goBack = () => {
    navigate("/Home");
  };
  return (
    <div>
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p>Loading...</p>
        </div>
      )}
      <div className="container">
        <div className="ml-2">
          <h4 style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            <span
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "15px",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={goBack}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-arrow-left-circle"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                />
              </svg>
            </span>
            <span style={{ display: "inline-block", verticalAlign: "middle", marginLeft: "5px" }}>Approval</span>

          </h4>
        </div>
        <div
          className="row  d-flex align-items-center justify-content-center"
          style={{
            height: "92vh",
          }}
        >
          <div className="col-10 col-md-6">
            Please use <b>{window.localStorage.getItem("fromEmail")}</b> for payment and{" "}
            <b>close the new window after successful payment</b> otherwise you will not be able to continue
            <br />
            <button className="btn btn-primary mt-3 w-100" onClick={proceedPayment}>
              Agree
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePayment;
