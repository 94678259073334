import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
const Header = ({ activeButton }) => {
  const navigate = useNavigate();
  const aiKey = window.localStorage.getItem("openai_key");

  const handleLogimail = () => {
    if (aiKey) {
      navigate("/Logimail");
    } else {
      navigate("/OpenAI");
    }
  };

  return (
    <div>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          <div tabIndex={0}>
            {activeButton === "logimail" ? (
              <Button size="small" shape="circular" appearance="primary" onClick={handleLogimail}>
                Logimail
              </Button>
            ) : (
              <Button size="small" shape="circular" appearance="primary" onClick={() => navigate("/Home")}>
                Switch to AI assistant
              </Button>
            )}
          </div>
        </div>

        <div className="flex-none">
          <div className="dropdown dropdown-end flex gap-4">
            <div>
              <Tooltip
                title="Templates"
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  src="assets/template.png"
                  alt="Templates"
                  style={{
                    cursor: "pointer",
                  }}
                  width={24}
                  height={24}
                  onClick={() => navigate("/AllTemplates")}
                />
              </Tooltip>
            </div>
            <div tabIndex={2} className="avatar cursor-pointer">
              <div className="w-6 rounded-full">
                <img alt="User" src="assets/user.png" />
              </div>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 pt-0 shadow bg-base-100 rounded-box w-52"
            >
              <li style={{ margin: "5px" }}>{window.localStorage.getItem("displayName")}</li>
              <li>
                <Link
                  to="/profile"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/subscriptions"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Subscriptions
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
