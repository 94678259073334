import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Spinner, makeStyles } from "@fluentui/react-components";
import { generateSummary, searchHSCode, generateShortSummary } from "./LogimailApi";
import { loadingPlace, showRoute } from "./Utilities/OpenDialogs";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
const useStyles = makeStyles({
  loaderoverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    opacity: ".7",
    zIndex: "10099",
  },
});
const Logimail = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const [loader, setLoader] = useState(false);
  const [sumaryData, setSummaryData] = useState("");
  const [hsCodeDescription, setHsCodeDescription] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [hsCodes, setHsCodes] = useState([]);
  const [loadingPlaces, setLoadingPlaces] = useState([]);
  window.CopyValue = (lat, lng) => {
    const textToCopy = `${lat}, ${lng}`;

    // Create a temporary textarea element
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = textToCopy;

    // Set the textarea's style to be invisible
    tempTextarea.style.position = "fixed";
    tempTextarea.style.left = "-9999px";

    // Append the textarea to the body
    document.body.appendChild(tempTextarea);

    // Select the text and copy it
    tempTextarea.select();
    try {
      document.execCommand("copy");
      console.log("Text copied to clipboard");
    } catch (err) {
      console.error("Could not copy text: ", err);
    }

    // Remove the textarea from the DOM
    document.body.removeChild(tempTextarea);
  };
  // Example usage:
  CopyValue("Value1,Value2");
  const generateSummaryClick = () => {
    setLoader(true);
    generateSummary()
      .then((res) => {
        setLoader(false);
        console.log(res);
        setSummaryData(res.emailSummary);
        setHsCodes(res.hsCodes);
        setLoadingPlaces(res.loadingPlaces);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error in generating summary", error);
      });
  };
  const generatesHORTSummaryClick = () => {
    setLoader(true);
    generateShortSummary()
      .then((res) => {
        setLoader(false);
        console.log(res);
        setSummaryData(res.emailSummary);
        setLoadingPlaces(res.loadingPlaces);
      })
      .catch((error) => {
        if (error.error.code === "invalid_api_key") {
          navigate("/OpenAI", { state: { message: "Invalid API Key. Please provide a valide key." } });
        }
        setLoader(false);
        console.log("error in generating short summary", error);
      });
  };
  const searchsCode = () => {
    setLoader(true);
    searchHSCode(codeValue)
      .then((res) => {
        console.log(res);
        setLoader(false);
        setHsCodeDescription(res);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error in fetching hs code description", error);
      });
  };
  const showLoadingPlace = (address) => {
    console.log(address);
    loadingPlace(address.address, "LoadingPlace");
  };

  const showUnloadingPlace = (address) => {
    console.log(address);
    loadingPlace(address.address, "LoadingPlace");
  };

  const handleShowRoute = (loadingAddress, unLoadingAddress) => {
    console.log(loadingAddress);
    showRoute(loadingAddress, unLoadingAddress);
  };

  return (
    <>
      <div
        style={{
          padding: "10px",
        }}
      >
        <Header activeButton="ai" />
      </div>
      {/* <Button
        variant="contained"
        sx={{
          height: "25px",
          fontSize: "11px",
          borderRadius: "20px",
          marginLeft: "20px",
        }}
        className="home_button"
        onClick={() => {
          navigate("/Home");
        }}
      >
        Switch to AI assistant
      </Button> */}
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p
            style={{
              fontWeight: "bold",
              color: "black",
            }}
          >
            Loading...
          </p>
        </div>
      )}
      <div className="p-3">
        <p dangerouslySetInnerHTML={{ __html: sumaryData }} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "17px",
        }}
      >
        <div className="home_buttons">
          <Button
            variant="contained"
            className="home_button"
            sx={{
              height: "25px",
              fontSize: "11px",
              borderRadius: "20px",
              padding: "6px 44px",
            }}
            onClick={generatesHORTSummaryClick}
          >
            SHORT SUMMARY
          </Button>
        </div>
        <div className="home_buttons">
          <Button
            variant="contained"
            size="small"
            className="home_button"
            sx={{
              height: "25px",
              fontSize: "11px",
              borderRadius: "20px",
              padding: "6px 48px",
            }}
            onClick={generateSummaryClick}
          >
            LONG SUMMARY
          </Button>
        </div>
        {hsCodes.map((item) => {
          return (
            <div key={item.HSCode} style={{ marginLeft: "27px" }}>
              <p className="py-2" style={{ fontWeight: "500" }}>
                The Harmonized System (HS) code
              </p>
              <input
                type="text"
                value={item.HSCode}
                className="mt-1 block w-[87%] px-3 py-1.5 bg-white border border-black rounded-full text-sm shadow-sm placeholder-slate-400 text-center ml-[20px] focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              />
              <p>{item.HSCodeDescription}</p>
            </div>
          );
        })}
        <>
          <div>
            <input
              type="text"
              placeholder="Enter HS code"
              onChange={(e) => setCodeValue(e.target.value)}
              className="mt-1 block w-[87%] px-3 py-1.5 bg-white border border-black rounded-full text-sm shadow-sm placeholder-slate-400 text-center ml-[20px] focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
            />
            <p>{hsCodeDescription}</p>
            <br />
            <div className="home_buttons">
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  fontSize: "11px",
                  borderRadius: "20px",
                  padding: "6px 70px",
                }}
                className="home_button"
                onClick={searchsCode}
              >
                SEARCH
              </Button>
            </div>
          </div>
        </>
        {loadingPlaces.length > 0 ? (
          loadingPlaces.map((item) => (
            <>
              <div className="home_buttons gap-8 mt-5">
                <Button
                  variant="contained"
                  sx={{
                    height: "25px",
                    fontSize: "11px",
                    borderRadius: "20px",
                  }}
                  className="home_button"
                  onClick={() => showLoadingPlace(item.loadingPlace)}
                >
                  LOADING
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: "25px",
                    fontSize: "11px",
                    borderRadius: "20px",
                  }}
                  className="home_button"
                  onClick={() => showUnloadingPlace(item.unloadingPlace)}
                >
                  UNLOADING
                </Button>
              </div>
              <div className="home_buttons">
                <Button
                  variant="contained"
                  sx={{
                    height: "25px",
                    fontSize: "11px",
                    borderRadius: "20px",
                    padding: "6px 70px",
                  }}
                  className="home_button"
                  onClick={() => handleShowRoute(item.loadingPlace.address, item.unloadingPlace.address)}
                >
                  Distance
                </Button>
              </div>
            </>
          ))
        ) : (
          <>
            <div className="home_buttons gap-8 mt-5">
              <Button
                variant="contained"
                size="small"
                sx={{
                  height: "25px",
                  fontSize: "11px",
                  borderRadius: "20px",
                }}
                className="home_button"
                disabled
              >
                LOADING
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  fontSize: "11px",
                  borderRadius: "20px",
                }}
                size="small"
                className="home_button"
                disabled
              >
                UNLOADING
              </Button>
            </div>
            <div className="home_buttons">
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  fontSize: "11px",
                  borderRadius: "20px",
                  padding: "6px 70px",
                }}
                size="small"
                className="home_button"
                disabled
              >
                DISTANCE/KM
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Logimail;
