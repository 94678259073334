import React, { useEffect } from "react"; // Import useEffect
import { Tooltip } from "@fluentui/react-components";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Input,
  Label,
  makeStyles,
} from "@fluentui/react-components";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    height: "65vh",
  },
});

const EditTemplate = ({ template, onEdit }) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState({ title: "", content: "" });

  useEffect(() => {
    // When the template prop changes, update the form data
    if (template) {
      setFormData({
        title: template.templateTitle,
        content: template.templateContent,
      });
    }
  }, [template]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle update logic here, using formData
    console.log("Updated template data:", formData);
  };
  const handleContentChange = (html) => {
    console.log(html);
  };
  return (
    <div>
      <Dialog
        style={{
          margin: "10px",
          height: "94vh",
        }}
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <DialogTrigger disableButtonEnhancement>
          <Tooltip content="Edit" relationship="label">
            <BorderColorIcon sx={{ fontSize: 17, cursor: "pointer", color: "blue" }} onClick={() => setOpen(true)} />
          </Tooltip>
        </DialogTrigger>
        <DialogSurface>
          <form onSubmit={handleSubmit}>
            <DialogBody>
              <div className="flex justify-between w-[220%]">
                <div>
                  <DialogTitle>Edit Template</DialogTitle>
                </div>
                <div>
                  <DialogTrigger disableButtonEnhancement>
                    <CloseIcon sx={{ cursor: "pointer" }} />
                  </DialogTrigger>
                </div>
              </div>
              <DialogContent className={styles.content}>
                <Label>Template Title</Label>
                <Input
                  required
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />
                <Label>Template Content</Label>

                <ReactQuill
                  theme="snow"
                  name="content"
                  value={formData.content}
                  onChange={handleContentChange} // Update content using this handler
                  style={{
                    height: "100px",
                    marginTop: "5px",
                  }}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ color: [] }],
                      ["link", "image"],
                      ["clean"],
                    ],
                    imageDrop: true,
                  }}
                  formats={[
                    "header",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "color",
                    "background",
                    "link",
                    "image",
                  ]}
                />
              </DialogContent>
              <DialogActions className="flex-row justify-end">
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </DialogTrigger>
                <Button type="submit" appearance="primary">
                  Update
                </Button>
              </DialogActions>
            </DialogBody>
          </form>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default EditTemplate;
