import React from "react";
import { DialogSurface, DialogContent, DialogBody, Dialog, Button } from "@fluentui/react-components";
import ReactQuill from "react-quill";
import Quill from "quill";
import ImageDrop from "quill-image-drop-and-paste";
// Register the module
Quill.register("modules/imageDrop", ImageDrop);

const ChangeSignature = ({
  openFileDialog,
  setOpenFileDialog,
  handleSubmitDialog,
  signature,
  signContent,
  handleMessageChange,
}) => {
  return (
    <div>
      <Dialog
        open={openFileDialog}
        onOpenChange={(event, data) => {
          setOpenFileDialog(data.open);
        }}
      >
        <DialogSurface>
          <div
            className="modal-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <h1 className="modal-title fs-5">Your Signature</h1>

            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setOpenFileDialog(false);
              }}
            ></button>
          </div>
          <DialogBody>
            <DialogContent>
              <form onSubmit={handleSubmitDialog}>
                <div>
                  <ReactQuill
                    theme="snow"
                    value={signContent}
                    onChange={handleMessageChange}
                    style={{
                      height: "180px",
                      marginTop: "5px",
                    }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ color: [] }, { background: [] }],
                        ["link", "image"],
                        ["clean"],
                      ],
                      imageDrop: true,
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "color",
                      "background",
                      "link",
                      "image",
                    ]}
                  />
                </div>
                <br />
                <br />
                <br />
                <br />

                <Button
                  shape="circular"
                  appearance="primary"
                  type="submit"
                  style={{
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingTop: "9px",
                    paddingBottom: "9px",
                    marginTop: "7px",
                    float: "right",
                  }}
                >
                  OK
                </Button>
              </form>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

export default ChangeSignature;
