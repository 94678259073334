export async function openMessageDialog() {
  var url = `https://www.logimail.site/MessageDialog.html?email=${window.localStorage.getItem("fromEmail")}`;
  //var url = `https://localhost:3000/MessageDialog.html?email=${window.localStorage.getItem("fromEmail")}`;

  let messageDialog;
  function processMessage(arg) {
    messageDialog.close();

    let message = arg.message;

    //Getting access and refresh toekn
    console.log(JSON.parse(message));

    return JSON.parse(message);
  }

  return new Promise((resolve, reject) => {
    Office.context.ui.displayDialogAsync(url, { height: 80, width: 60 }, function (result) {
      if (result.error) {
        reject(result.error);
      } else {
        messageDialog = result.value;
        messageDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogMessageReceived, function (arg) {
          try {
            const data1 = processMessage(arg);
            resolve(data1); // Resolve the promise with data1 value
          } catch (error) {
            reject(error); // Reject the promise if an error occurs
          }
        });
      }
    });
  });
}
