export async function makePayment(url) {
  return new Promise((resolve, reject) => {
    let paymentDialog;

    function PayprocessMessage(arg) {
      if (arg.error === 12006) {
        resolve();
      }
    }

    Office.context.ui.displayDialogAsync(url, { height: 60, width: 40 }, function (result) {
      if (result.error) {
        reject(result.error);
      } else {
        paymentDialog = result.value;
        paymentDialog.addEventHandler(Microsoft.Office.WebExtension.EventType.DialogEventReceived, PayprocessMessage);
      }
    });
  });
}
